<template>
  <!--components/LoginForm.vue-->
  <div>
    <div v-if="false && waiting_for_backend_services_ref.valueOf()">
      <div class="container-fluid form-inline header_div" >
          <a href="/" class="logo_link"><img src="/vstatic/images/logo.png" class="logo" /></a>
      </div>
      <div class="error_page_container" >
      </div>
    </div>


    <div class="login-container" v-if="! waiting_for_backend_services_ref.valueOf()">
      <div class="box login-left-area">
        <div class="text-center">
          <img src="/vstatic/images/login_image.png" width="264" />
          <p class="text-white">
            AI Created and Expert Curated Automation
          </p>
          <p class="text-white"></p>
        </div>
      </div>

      <div class="box">
        <form action="" method="POST" class="form" role="form" id="my_login_form" autocomplete="off" aria-autocomplete="none">
          <input name="_csrf_token" type="hidden" :value="csrf" />

          <p v-if="! show_normal_login_form">You can sign in or sign up using third party social sign-in provider below:</p>
          <p id="login_error" v-if="login_error_ref.valueOf() != ''">{{ login_error_ref.valueOf() }}</p>
          <p id="logout_message" v-if="logout_message">{{ logout_message }}</p>

          <div v-if="show_normal_login_form">
            <input id="next" name="next" type="hidden" value="/" />
            <input id="reg_next" name="reg_next" type="hidden" value="/" />

            <div class="form-group">
              <div class="row">
                <input class="form-control" id="email" name="email" placeholder="Email or Username" required="" type="text" tabindex="1" />
              </div>
            </div>
            <div class="form-group">
              <div class="row" id="forgot_password_container"></div>
            </div>
            <div class="form-group">
              <div class="row">
                <div id="forgot_password_container">
                  <a href="/user/forgot-password" id="forgot_password_link">Forgot your Password?</a>
                </div>
                <input class="form-control" id="password" name="password" placeholder="Password" required="" type="password" @keyup.enter="onSubmit" tabindex="2" />
              </div>
            </div>
            <div class="form-group" id="hidden_org_container">
              <div class="row">
                <input class="form-control" id="org" name="org" placeholder="Organization" type="text" :value="org" style="display: none" />
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="checkbox">
                  <label> <input id="remember_me" name="remember_me" type="checkbox" value="y" /> Remember me </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <input type="button" class="btn btn-default btn-primary btn-block class_sign_in" value="Sign in" @click="onSubmit" tabindex="3" />
              </div>
            </div>



            <div class="form-group" v-if="socialSignin">
              <div class="row">
                <div class="text-center my-2">OR</div>
              </div>
            </div>
          </div>

          <SocialLoginForm :adminFlags="adminFlags"/>

        </form>
      </div>
    </div>
    <copyright/>
  </div>
</template>
<script setup>
let waiting_for_backend_services_ref = ref(false);
//let adminFlags = useAdminFlags();
let adminFlags = {};
let socialSignin = false;
const runtimeConfig = useRuntimeConfig();
const isLoading = ref(false);

const route = useRoute();
let login_error_ref = ref("");
let login_error_code = route.query.error;
if (login_error_code == "1") {
  login_error_ref.value = "A user with the email does not exist.";

} else if (login_error_code == "2") {
  login_error_ref.value = "Invalid credential.";

} else if (login_error_code == "3") {
  login_error_ref.value = "Your email setting is set to private or your name is not configured.";

} else if (login_error_code == "4") {
  login_error_ref.value = "Email domain is not allowed.";

} else if (login_error_code == "5") {
  login_error_ref.value = "Your password was successfully reset.  Please login.";

} else if (login_error_code == '6') {
  login_error_ref.value = "There was a problem with social sign-in authentication.  Please try again.";

} else if (login_error_code == '7') {
  login_error_ref.value = "User is not confirmed.";
}

let logout_message = "";
if (route.path.startsWith("/vlogout")) {
  // logout_message = "You have successfully signed out of the application."
}

async function onSubmit() {
  if (isLoading.value) return;
  isLoading.value = true;

  const env = await getEnv();
  let url = "/user/sign-in";
  let form = {};

  let email = document.getElementById("email").value;
  let password = document.getElementById("password").value;
  if ((email == '') || (password == '')) {
    login_error_ref.value = "Username and password fields are required";
    return false;
  }

  showWaitPage();
  form["email"] = email;
  form["password"] = password;
  form["org"] = document.getElementById("org").value;
  form["from_nuxt"] = 1;

  let data = undefined
  try {
    data = await logFetchAJAX(getUrl(url), {
      method: "post",
      body: form,
    });
  } catch (ex) {
    // ignore.  An alert is already displayed
  }

  isLoading.value = false;
  hideWaitPage();
  // data.value is the data that we want
  // error.value.data.error is the error message

  if (typeof(data) != 'undefined') {
    if (data.responsecode == "True") {
      let redirect_url = data.redirect_url;
      window.location.href = redirect_url;
    } else {
      if (data.message == 'User is not confirmed') {
        window.location.href = "/vlogin?error=7";
      } else if (data.hasOwnProperty('errorCode')) {
        //window.location.href = `/vlogin?error=${data.errorCode}`;
        login_error_ref.value = data.message;
      } else {
        window.location.href = "/vlogin?error=2";
      }
    }
  }
}

const headers = useRequestHeaders();

let env_state = useEnvState();
let env = env_state.value;
if ((typeof(env) == 'undefined') || (Object.keys(env).length === 0)) {
  env = await getEnv();
}
let community = env.community || '';
if (community.toLowerCase() == 'true') {
  community = true;
} else {
  community = false;
}

async function getOrg() {
  if (route.query.hasOwnProperty('org')) {
    return route.query.org;
  } else {
    let hostname = '';
    if (typeof(window) != 'undefined') {
      hostname = window.location.hostname;
    } else {
      hostname = headers["host"];
    }
    let parts = hostname.split(/\./);
    hostname = hostname.replace(/\..*/, ""); // We only care about the first part before the first dot.  Remove the first dot and everything after it.
    let org = hostname.split(/\-/)[0];
    if (["demo", "app", "localhost", "poc", "community", "daily", "dev", "khai"].includes(org.toLowerCase())) {
      /* 
      If the first part is one of the generic ones, use the default_org.
      So the pattern for the hostname should be tenantName.dagknows.com, tenantName-poc.dagknows.com, orgName.dagknows.com, orgName-poc.dagknows.com
      */

      return env.default_org;
    } else if (parts.length == 1) {
      // This should not be happening, but our nginx and nuxt setup is giving us stupid information, such as nuxt-upstream.
      // TODO: figure out a better way to do this.
      return env.default_org;
    } else {
      return org;
    }
  }
}

const org = await getOrg();

async function fetchAuthProviderSettings() {
  if ((typeof(org) == 'undefined') || (org == null) || (org.trim() == '')) {
    return "";
  }

  try {
    if (typeof window != "undefined") {
      let url = "/get_login_settings?org=" + org + "&ts=" + new Date().getTime();
      if (window.location.port == 3000) {
        url = getUrl(url);
      }
      const raw_data = await logFetchAJAX(url,{});
      const data = { value: raw_data };
      return data;
    } else {
      let url = "http://req-router:8888/get_login_settings?server_side=Y&org=" + org;
      console.log("Running server-side logic");
      console.log("URL: " + url);
      console.log("org: " + org);
      const { data, error } = await logFetchAJAX(url,{});
      return data;
    }
  } catch (ex) {
    return undefined;
  }
}

const result = await fetchAuthProviderSettings();
if (result && result.value && result.value.flags) {
  const flags = result.value.flags;
  adminFlags["flag_enable_sso_azure"] = flags["flag_enable_sso_azure"] ? true : false;
  adminFlags["flag_enable_sso_github"] = flags["flag_enable_sso_github"] ? true : false;
  adminFlags["flag_enable_sso_google"] = flags["flag_enable_sso_google"] ? true : false;
  adminFlags["flag_enable_sso_okta"] = flags["flag_enable_sso_okta"] ? true : false;
  adminFlags["flag_enable_sso_slack"] = flags["flag_enable_sso_slack"] ? true : false;

  if (adminFlags["flag_enable_sso_azure"] || adminFlags["flag_enable_sso_github"] || adminFlags["flag_enable_sso_google"] || adminFlags["flag_enable_sso_okta"] || adminFlags["flag_enable_sso_slack"]) {
    socialSignin = true;
  }
} else {
  console.log(result);
  let just_started = false;
  let server_start_time = env.server_start_time;
  let now = (new Date()).getTime() / 1000;
  if (now - parseInt(server_start_time) < 4 * 60) {
      // The server was restarted within 4 minutes
      just_started = true;
  }
  if (just_started) {
    waiting_for_backend_services_ref.value = true;

    if (typeof(window) != 'undefined') {
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }
  }
}


let sa = route.query.sa; // sa abbreviate for "show all", which mean show both the social sign-in buttons, and the normal login form.
let show_normal_login_form = true;
if (community) {
  show_normal_login_form = true;  // Change this to false if we do not want to display the username and password fields for community
  if ((typeof(sa) != 'undefined') && (sa != '')) {
    show_normal_login_form = true;
    
    if (typeof(window) != 'undefined') {
      localStorageSetItemWrapper('show_normal_login_form','1');
    }
  } else {
    if (typeof(window) != 'undefined') {
      sa = localStorage.getItem('show_normal_login_form');
      if ((sa != null) && (sa == '1')) {
        show_normal_login_form = true;
      }
    }
  }
}

let csrf = await generateCSRFToken();

</script>

<style scoped>
#login_form_iframe {
  padding-top: 20px;
  width: 100%;
  height: 90vh;
}
#my_login_form {
  width: 524px;
  margin: 10px auto;
}
.btn-block {
  width: 100%;
}
.btn-login {
  position: relative;
  border: 1px solid #ccc !important;
}
.btn-login:hover {
  background-color: white !important;
  color: black !important;
}
.btn-login img {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 5px;
}
#forgot_password_container {
  margin-bottom: 10px;
  padding-left: 0px;
}
#forgot_password_link {
  margin: 10px 0px;
  color: black;
  text-decoration: none;
  padding-left: 0px;
}

.register_link {
  color: black;
  text-decoration: none;
  float: right;
}

#hidden_org_container {
  margin-bottom: 1em;
}
#login_error {
  border: 1px solid red;
  margin: 10px -12px;
  padding: 10px;
  border-radius: 5px;
}
#logout_message {
  border: 1px solid green;
  margin: 10px -12px;
  padding: 10px;
  border-radius: 5px;
  background: #ebffeb;
}
.class_sign_in {
  border-radius: 5px;
}

.login-left-area {
  background-color: #0f63e2;
}
.login-container {
  display: flex;
  height: 100vh; /* adjust as needed */
}

.box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_div {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.header_div .logo {
  width: 200px;
}
.header_div button,
.header_div a {
  margin: 5px 10px;
}

.error_page_container {
    margin-top: 10px;
    text-align: center;
}

</style>
